.technologySection {
  /* margin: 50px 0; */
  padding: 50px 90px;
  text-align: center;
  background-color: white;
  /* padding: 20px 60px; */
}

.technologySection h6 {
  font-size: 16px;
  color: #0035e9;
  letter-spacing: 1.5px;
}

.technologySection h3 {
  /* font-size: 2rem;
      font-weight: bold; */
  font-family: "Saira Extra Condensed", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 50px;
  line-height: 55px;
  letter-spacing: 2px;
  color: #0035e9;
  margin-bottom: 30px;
}

.technologySection .cardContainer {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .technologySection {
    /* margin: 50px 0; */
    padding: 30px 10px;
    /* padding: 20px 60px; */
  }

  .technologySection h6 {
    font-size: 18px;
  }

  .technologySection h3 {
    letter-spacing: 1.3px;
    font-size: 35px;
    line-height: 45px;
    color: #0035e9;
    margin-bottom: 20px;
  }

  .technologySection .cardContainer {
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    margin: auto;
    row-gap: 10px;
  }
}
