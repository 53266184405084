/* src/VerticalCarousel.css */
.vertical-carousel {
  height: 100vh; /* Adjust the height as needed */
  overflow: hidden;
  position: relative;
}

.carousel-content {
  display: flex;
  flex-direction: column;
  transition: transform 0.5s linear;
}

.carousel-item {
  /* flex-shrink: 0; */
  width: 100%;
}
