.cardDiv {
  background: transparent linear-gradient(118deg, #0035e9, #033eb4) 0 0
    no-repeat padding-box;
  color: white;
  width: 95%;
  /* height: 200px; */
  height: 185px;

  text-align: center;
  padding: 30px 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* cursor: pointer; */
  position: relative;
  overflow: hidden;
}

.cardDiv img {
  height: 70px;
  margin-bottom: 15px;
}

.cardDiv h5 {
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 15px;
}

.cardDiv p {
  margin: 0;
  font-size: 16px;
  position: absolute;
  /* bottom: 15px; */
  transform: translateY(1000%); /* Initially move the element out of view */
  transition: transform 0.4s; /* Add a transition for smooth animation */
}

.cardDiv:hover p {
  /* display: block; */
  transform: translateY(0); /* Move the element back into view on hover */
}

@media screen and (max-width: 768px) {
  .cardDiv {
    /* height: 180px; */
    height: 155px;
    padding: 20px 20px;
  }

  .cardDiv img {
    height: 60px;
    margin-bottom: 10px;
  }

  .cardDiv h5 {
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-size: 14px;
  }

  .cardDiv p {
    margin: 0;
    font-size: 12px;
    /* display: none; */
    position: absolute;
    /* bottom: 10px; */
    transform: translateY(0); /* Initially move the element out of view */
  }

  .cardDiv:hover p {
    /* display: block; */
    transform: translateY(0); /* Move the element back into view on hover */
  }
}
