.connectSection {
  /* margin: 50px 0; */
  padding: 50px 0;
  text-align: center;
  background-color: #0035e9;
  /* padding: 20px 60px; */
}

.connectSection h6 {
  font-size: 16px;
  color: white;
  letter-spacing: 1.5px;
}

.connectSection h3 {
  /* font-size: 2rem;
  font-weight: bold; */
  font-family: "Saira Extra Condensed", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 50px;
  line-height: 55px;
  letter-spacing: 2px;
  color: white;
  margin-bottom: 30px;
}

.connectSection .emailForm {
  /* border: 1px solid grey; */
  border-radius: 25px;
  width: 60%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding-left: 15px;
  /* box-shadow: 0 0 3pt 1pt #bbbaba; */
  background-color: white;
}

.connectSection .emailForm input {
  width: 100%;
  border-radius: 25px;
  border: none;
  padding: 10px;
}
.connectSection .emailForm input:focus {
  outline: none;
}

.connectSection .emailForm button {
  /* box-shadow: 0 0 3pt 1pt #bbbaba; */
  background-color: #a6edd9;
  color: black;
  font-weight: bold;
  border-radius: 25px;
  padding: 10px 25px;
  width: 10rem;
  border: none;
  transition: 0.3s ease-in;
  /* text-transform: uppercase; */
}

.connectSection .emailForm button:hover {
  /* background-color: black; */
  /* color: white; */
  transform: scale(1.03);
}

@media screen and (max-width: 768px) {
  .connectSection h6 {
    font-size: 18px;
  }

  .connectSection h3 {
    letter-spacing: 1.3px;
    font-size: 40px;
    line-height: 45px;
    color: white;
    margin-bottom: 25px;
  }

  .connectSection .emailForm {
    width: 90%;
  }

  .connectSection .emailForm button {
    padding: 10px 20px;
    width: 8rem;
  }
}
