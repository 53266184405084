@import url("https://fonts.googleapis.com/css2?family=Saira+Extra+Condensed:wght@300;400;500&display=swap");

.navbarWrapper {
  position: fixed;
  top: 0px;
  background-color: white;
  z-index: 1000;
  width: 100%;
  padding: 0px 15px;
}

.navMenu ul li p.active {
  color: #0035e9;
}

.logo h1 {
  color: #0035e9;
  font-family: "Saira Extra Condensed", sans-serif;
  font-size: 50px;
  font-style: italic;
  font-weight: bold;
}

.logo p {
  color: #0035e9;
  font-family: "Saira Extra Condensed", sans-serif;
  margin: 0;
  letter-spacing: 1.6px;
  font-size: 18px;
}

.navbar {
  padding: 15px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.navbar .logo img {
  fill: none;
  width: 135px;
}

.navMenu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navMenu ul {
  display: flex;
  list-style: none;
  justify-content: space-evenly;
  align-items: center;
  margin: 0;
}

.navMenu ul li p {
  /* margin: 0 10px; */
  padding: 5px 30px 5px 25px;
  color: #161521;
  font-size: 17px;
  font-weight: 400;
  font-family: Opensaucesans, sans-serif;
  display: flex;
  align-items: center;
  margin: 0;
  cursor: pointer;
}

/* dropdown menu div */
.navMenu ul .dropdownMenu {
  position: relative;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */
}

/* dropdown content hidden by default */
.navMenu ul .dropdownMenu .dropdownContent {
  display: block;
  /* top: 50px; */
  position: absolute;
  background-color: rgba(255, 255, 255, 0.927);
  width: 135%;
  padding: 5px 15px;
  box-shadow: 0 0 3pt 1pt #0036e95b;
}

.navMenu ul .dropdownMenu .dropdownContent h6 {
  font-family: Opensaucesans, sans-serif;
  font-size: 16px;
  cursor: pointer;
  margin-top: 5px;
}

.navMenu ul .dropdownMenu .dropdownContent h6:hover {
  color: #0035e9;
}

/* show dropdown content on hover */
/* .navMenu ul .dropdownMenu:hover .dropdownContent {
  display: block;
} */

.sellBtn {
  background-color: #a6edd9;
  padding: 8px 25px;
  transition: 0.3s ease-in;
  font-weight: bold;
  font-family: Opensaucesans, sans-serif;
  text-transform: uppercase;

  /* font-size: 14px; */
}

.navListItem.sellBtn {
  background-color: #a6edd9;
  margin-left: 30px;
  padding: 8px 25px;
  transition: 0.3s ease-in;
  font-weight: bold;
  font-family: Opensaucesans, sans-serif;
  text-transform: uppercase;

  /* font-size: 14px; */
}

.sellBtn:hover {
  background-color: #161521;
  color: white;
  cursor: pointer;
}

.navbar .menuBtn {
  font-size: 40px;
  font-weight: bolder;
  cursor: pointer;
  display: none;
  color: #0035e9;
}

.dropdown {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 15px 10px 15px;
  background-color: white;
  width: 100%;
  height: 100vh;
  font-size: 16px;
}

.dropdown .closeBtn {
  color: #0035e9;
  width: 100%;
  display: flex;
  justify-content: right;
  margin: 5px;
  border: none;
  background-color: transparent;
}

.dropdown .dropdownLink p {
  color: black;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* .dropdown .dropdownLink a.active {
  color: #0035e9;
} */

.dropdown .dropdownLink .dropdownMenu {
  position: relative;
}

.dropdown .dropdownLink .dropdownContent {
  margin: 5px 0;
  padding-left: 20px;
  font-family: Opensaucesans, sans-serif;
}

@media screen and (max-width: 768px) {
  .navbar {
    padding: 15px 8px;
  }

  .navbar .menuBtn {
    display: flex;
  }

  .navMenu {
    display: none;
  }

  .dropdown .dropdownLink.sellBtn {
    width: 55%;
    text-align: center;
  }
}
