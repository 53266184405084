.imgDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sectionImg {
  height: 300px;
  border-radius: 8px;
  /* flex: 1; */
  /* display: flex; */
}

@media screen and (max-width: 768px) {
  .imgDiv {
    display: flex;
    align-items: center;
    /* justify-content: flex-start; */
    margin: 20px 0;
  }

  .sectionImg {
    /* height: 250px; */
    /* width: 100vh; */
    height: 200px;
  }
}
