.logisticsSection hr {
  margin: 0;
  opacity: 1;
}

/* .logisticsTechContentWrapper {
  padding: 50px 60px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
} */
