.geographicSection {
  /* margin: 50px 0; */
  padding: 50px 0 0 0;
  text-align: center;
  background-color: #0035e9;
  /* height: 400px; */
  /* padding: 20px 60px; */
}

.geographicSection h6 {
  font-size: 16px;
  color: white;
  letter-spacing: 1.5px;
}

.geographicSection h3 {
  /* font-size: 2rem;
    font-weight: bold; */
  font-family: "Saira Extra Condensed", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 50px;
  line-height: 55px;
  letter-spacing: 2px;
  color: white;
  margin-bottom: 30px;
}

.geographicSection img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .geographicSection {
    padding: 20px 0 0 0;
  }
  .geographicSection h6 {
    font-size: 18px;
  }

  .geographicSection h3 {
    letter-spacing: 1.3px;
    font-size: 35px;
    line-height: 45px;
    color: white;
    margin-bottom: 15px;
  }
}
