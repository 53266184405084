.servicesSection {
  margin: 50px 0;
  width: 100%;
  background-color: #0035e9;
  padding: 50px 60px 70px 60px;
  overflow: hidden;
}

.servicesWrapper {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1.6fr 1fr;
  column-gap: 70px;
  overflow: hidden;
}

.servicesSection .smallHeader {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 35px;
  /* font-family: "Saira Extra Condensed", sans-serif; */
  font-size: 23px;
  font-weight: normal;
  color: white;
  -webkit-text-stroke: 1px white;
}

@media screen and (max-width: 768px) {
  .servicesSection {
    margin: 20px 0;
    width: 100%;
    background-color: #0035e9;
    padding: 40px 15px;
    overflow: hidden;
  }

  .servicesWrapper {
    display: grid;
    grid-template-rows: 1fr 1.5fr;
    grid-template-columns: 1fr;
  }

  .servicesSection .smallHeader {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 25px;
    /* font-family: "Saira Extra Condensed", sans-serif; */
    font-size: 20px;
    font-weight: normal;
    color: white;
  }
}
