.serviceSelector {
  background-color: white;
  padding: 20px 35px 20px 35px;
  text-align: left;
  color: grey;
  border-radius: 20px;
  height: 100%;
}

.serviceSelector p {
  margin: 20px 0;
  cursor: pointer;
  font-size: 19px;
  line-height: 26px;
  /* line-height: 20px; */
}

.selectedService {
  font-weight: bold;
  color: black;
}

@media screen and (max-width: 768px) {
  .serviceSelector {
    background-color: white;
    padding: 15px 30px 15px 30px;
    text-align: left;
    color: grey;
    border-radius: 20px;
    height: 100%;
    z-index: 100;
  }

  .serviceSelector {
    grid-row: 1;
  }

  .serviceSelector p {
    margin: 15px 0;
    cursor: pointer;
    font-size: 18px;
    line-height: 23px;
    /* line-height: 20px; */
  }
}
