.bottomSection {
  width: 100%;
  height: 70vh;
}

.upperDiv {
  background-color: rgb(247, 243, 243);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2% 0;
}

.lowerDiv {
  background-color: #0035e9;
  height: 90%;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lowerDiv h3 {
  font-family: "Saira Extra Condensed", sans-serif;
  font-weight: bold;
  font-size: 70px;
  line-height: 70px;
  color: white;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

/* .lowerDiv .sell {
  color: transparent;
  -webkit-text-stroke: 1px white;
  font-weight: normal;
} */

.lowerDiv .contactBtn {
  background-color: #0035e9;
  color: white;
  text-align: left;
  text-transform: uppercase;
  border: 1px solid white;
  margin-top: 10px;
  padding: 15px 60px;
  font-weight: 700;
  transition: 0.5s ease-in-out;
}

.lowerDiv .contactBtn:hover {
  background-color: white;
  color: #0035e9;
}

@media screen and (max-width: 768px) {
  .upperDiv {
    padding: 4%;
  }

  .upperDiv h3 {
    font-size: 25px;
    text-align: center;
  }

  .lowerDiv {
    height: 80%;
  }

  .lowerDiv h3 {
    text-align: center;
    font-size: 53px;
    line-height: 53px;
  }

  .lowerDiv .contactBtn {
    margin-top: 8px;
    padding: 15px 50px;
  }
}
