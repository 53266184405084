.distributorsSection {
  width: 100%;
  padding: 0px 60px 50px 60px;
}

.distributorsSection .smallHeader {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 45px;
  /* font-family: "Saira Extra Condensed", sans-serif; */
  font-size: 23px;
  font-weight: normal;
  color: #0035e9;
  -webkit-text-stroke: 1px #0035e9;
}

.distributorsSection .contentDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.distributorsSection .contentDiv h3 {
  /* font-size: 2rem;
    font-weight: bold; */
  flex: 1;
  font-family: "Saira Extra Condensed", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 60px;
  line-height: 55px;
  color: #0035e9;
  margin-bottom: 30px;
}

.distributorsSection .contentDiv p {
  flex: 0.9;
  margin: 0;
  padding: 0;
  /* padding: 0 0 0 60px; */
  /* width: 45%; */
  font-size: 15px;
}

/* .distributorsSection .cardsContainer {
  margin: auto;
  margin-top: 20px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 25px;
  column-gap: 90px;
} */

.distributorsSection .cardsContainer {
  padding: 0 15px;
  margin: auto;
  margin-top: 10px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 10px;
  column-gap: 10px;
}

@media screen and (max-width: 768px) {
  .distributorsSection {
    padding: 0px 20px 40px 20px;
  }

  .distributorsSection .smallHeader {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 25px;
    /* font-family: "Saira Extra Condensed", sans-serif; */
    font-size: 20px;
    font-weight: normal;
    color: #0035e9;
  }

  .distributorsSection .contentDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
  }

  .distributorsSection .contentDiv h3 {
    letter-spacing: 1.2px;
    font-size: 50px;
    line-height: 45px;
    color: #0035e9;
    margin-bottom: 15px;
  }

  .distributorsSection .cardsContainer {
    padding: 0;
    margin-top: 20px;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    row-gap: 5px;
    column-gap: 5px;
  }
}
