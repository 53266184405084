.serviceContent {
  color: white;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  opacity: 1;
}

.serviceContent hr {
  border-top: 3px solid #a6edd9;
  opacity: 1;
  width: 15%;
}

.serviceContent h3 {
  /* font-size: 30px; */
  font-family: "Saira Extra Condensed", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 50px;
  line-height: 60px;
}

.serviceContent p {
  margin-top: 20px;
  font-size: 20px;
}

.slideIn {
  animation: slideIn 0.3s ease-in-out forwards;
  opacity: 1;
}

.slideOut {
  animation: slideOut 0.3s ease-in-out forwards;
  opacity: 0;
}

@keyframes slideIn {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

@media screen and (max-width: 768px) {
  .serviceContent {
    grid-row: 2;
    margin: 15px auto;
    height: 100%;
  }

  .serviceContent h3 {
    /* font-size: 30px; */
    font-family: "Saira Extra Condensed", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 40px;
    line-height: 45px;
  }

  .serviceContent p {
    margin-top: 10px;
    font-size: 18px;
  }
}
