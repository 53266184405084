@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: white;
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

.draftDiv {
  height: 500px;
  padding: 20px 30px;
}

.draftDiv h3 {
  color: rgb(72, 72, 72);
  font-size: 1.6rem;
}

.logisticsTechContentWrapper {
  padding: 100px 70px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  /* align-content: center; */
  /* display: flex;
  align-items: center;
  justify-content: space-between; */
}

@media screen and (max-width: 768px) {
  .logisticsTechContentWrapper {
    padding: 60px 20px;
    display: flex;
    flex-direction: column;
    /* column-gap: 0px;

    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr; */
  }
}

.container {
  flex: 1;
  width: 20%;
  /* display: flex; */
  /* align-items: center; */
  height: inherit;
  /* width: 100vw; */
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  overflow: hidden;
}

.scroll-parent {
  position: relative;
  /* width: 100vw; */
  width: 100%;
  height: 100%;
  /* padding: 2rem 0; */
  /* overflow-y: hidden; */
}

.scroll-element {
  border: 1px solid white;
  width: inherit;
  height: 100%;
  /* position: absolute; */
  /* left: 0px;
  top: 0px; */
  position: relative;
  /* display: flex; */
  /* flex-direction: column; */
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr;
  row-gap: 20px;
  /* align-items: center; */
  /* justify-content: space-around; */
}

/* .scroll-element h1 {
  border: 2px solid yellow;
  width: 100%;
  margin: 20px;
} */

.scroll-element img {
  border: 2px solid yellow;
  width: 80%;
  height: 80%;
  /* margin: 20px; */
}

.primary {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 20px;
  animation: primary 20s linear infinite;
}

.secondary {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 20px;
  animation: secondary 20s linear infinite;
}

@keyframes primary {
  from {
    top: 0%;
  }
  to {
    top: -100%;
  }
}

@keyframes secondary {
  from {
    top: 100%;
  }
  to {
    top: 0%;
  }
}
