.whatsappIcon {
  position: fixed;
  right: 15px;
  bottom: 20px;
  transition: 0.3s ease-in;
  z-index: 9999999;
  border-radius: 50%;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  background-color: #25d366;
}

.whatsappIcon svg {
  color: #fff;
  margin: 7px;
}

.whatsappIcon:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.messageIcon {
  position: fixed;
  right: 15px;
  bottom: 20px;
  transition: 0.3s ease-in;
  z-index: 9999999;
}

.messageIcon img {
  width: 55px;
}

.messageIcon:hover {
  transform: scale(1.1);
  cursor: pointer;
}
