.industryCard img {
  color: #0035e9;
  height: 45px;
}

.industryCard hr {
  border-top: 2px solid #a6edd9;
  width: 20%;
  margin: 5px 0 5px 0;
  opacity: 1;
}

.thumbnails h6 {
  /* width: 40%; */
  font-size: 35px;
}

.thumbnails {
  /* width: calc(100% / 4); */
  aspect-ratio: 1/1;
  /* height: 80%;
  width: 80%; */
  /* margin: 10px; */
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s;
}

.thumbnails img {
  width: 100%;
  object-fit: cover;
  /* object-position: 50% 20%; */
}

.black {
  text-align: center;
  background: #000;
  background-blend-mode: multiply;
  color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.7;
}

.title {
  color: #fff;
  font-size: 2em;
  align-self: center;
  position: absolute;
  z-index: 10;
}

.black,
.title {
  transition: opacity 0.3s;
}

.thumbnails:hover {
  /* cursor: pointer; */
  transform: scale(1.02);
  z-index: 20;
}

.thumbnails:hover .black,
.thumbnails:hover .title {
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .industryCard img {
    height: 40px;
  }

  .thumbnails .title h6 {
    /* width: 40%; */
    font-size: 25px;
  }
}
