/* .myCarousel {
  width: 100vw;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
} */

.myCarouselPlaceholder {
  flex: 1;
  width: 20%;
}

.myCarousel {
  flex: 1;
  width: 20%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  /* overflow: hidden; */
  /* background-color: black; */
}

@media screen and (max-width: 768px) {
  .myCarousel {
    display: none;
  }
}
