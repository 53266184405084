@import url("https://fonts.googleapis.com/css2?family=Saira+Extra+Condensed:wght@300;400;500&display=swap");

.homeSection {
  background-color: #0035e9;
  padding: 100px 50px 0px 50px;
  /* padding-bottom: 110px; */
  color: white;
  display: flex;
}

/* .whatWeDo {
    padding: 0px 50px;
    margin-top: 130px;
    display: flex;
    margin-bottom: 50px;
  } */

.homeSection .leftContainer {
  margin-top: 40px;
  margin-right: 20px;
  flex: 1;
}

.homeSection h2 {
  color: white;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 20px;
  /* font-family: "Saira Extra Condensed", sans-serif; */
  font-size: 23px;
  font-weight: normal;
  -webkit-text-stroke: 1px white;
  /* line-height: 20px; */
}

.homeSection h1 {
  font-family: "Saira Extra Condensed", sans-serif;
  font-weight: bold;
  font-size: 70px;
  line-height: 70px;
  color: white;
}

.homeSection .visionaries {
  color: transparent;
  -webkit-text-stroke: 1px white;
  font-weight: normal;
}

.homeSection p {
  padding: 25px 0;
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
}

.homeSection .businessBtn {
  background-color: #0035e9;
  color: white;
  text-align: left;
  text-transform: uppercase;
  border: 1px solid white;
  margin-top: 10px;
  padding: 10px 40px;
  font-weight: 700;
  transition: 0.5s ease-in-out;
}

.homeSection .businessBtn:hover {
  background-color: white;
  color: #0035e9;
}

@media screen and (max-width: 768px) {
  .homeSection {
    background-color: #0035e9;
    padding: 100px 30px 50px 30px;
  }

  .homeSection h2 {
    font-size: 20px;
  }
  .homeSection h1 {
    font-size: 50px;
    line-height: 50px;
  }

  .homeSection p {
    padding: 20px 0;
    font-size: 16px;
    font-weight: 300;
    line-height: 23px;
  }
}
