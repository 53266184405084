.supportFormDiv {
  padding: 100px 190px;
}

.supportFormDiv h1 {
  text-align: center;
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-bottom: 30px;
  color: #0035e9;
  font-family: "Saira Extra Condensed", sans-serif;
  font-size: 65px;
}

.supportForm {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* .supportForm .inputGroup {
  display: flex;
  width: 100%;
  justify-content: space-between;
} */

.supportForm .formInput {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 15px;
}

.supportForm label {
  font-size: 14px;
  color: rgb(71, 70, 70);
}

.supportForm input {
  margin: 6px 0;
  padding: 1%;
  /* border-radius: 8px; */
  /* box-shadow: 0 0 3pt 1pt #d3d3d3; */
  background-color: transparent;
  resize: none;
  outline: none;
  border: 1px solid rgb(196, 195, 195);
  width: 90%;
}

.supportForm textarea {
  margin: 6px 0;
  padding: 1%;
  background-color: transparent;
  resize: none;
  outline: none;
  border: 1px solid rgb(196, 195, 195);
  width: 100%;
}

.supportForm input:focus,
.supportForm textarea:focus {
  outline: 2px solid #0035e9;
}

.supportForm .actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.supportForm .actions button {
  width: 12rem;
  border: none;
  background-color: #a6edd9;
  padding: 12px 25px;
  transition: 0.3s ease-in;
  font-weight: bold;
  font-family: Opensaucesans, sans-serif;
  text-transform: uppercase;
  font-size: 17px;
  /* font-size: 14px; */
}

.supportForm .actions button:hover {
  background-color: #161521;
  color: white;
  cursor: pointer;
}

.supportForm .actions a p {
  cursor: pointer;
  font-style: italic;
  font-size: 0.8rem;
  text-decoration: underline;
  text-decoration-color: rgb(133, 132, 132);
  transition: 0.3s ease-in;
  color: rgb(54, 54, 54);
}

.supportForm .actions a p:hover {
  color: #116d6e;
}

@media screen and (max-width: 768px) {
  .supportForm .actions button {
    margin-bottom: 20px;
  }

  .supportForm .actions {
    flex-direction: column;
    align-items: center;
  }

  .supportFormDiv {
    padding: 100px 30px;
  }

  .supportFormDiv h1 {
    letter-spacing: 1.2px;
    margin-bottom: 20px;
    font-size: 50px;
  }

  .supportForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  .supportForm .formInput {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 8px;
  }

  .supportForm input {
    width: 100%;
  }
}
