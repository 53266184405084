.pageHeader {
  padding: 100px 0 0 0;
  background-color: #0035e9;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  height: 90vh;
}

.pageHeader .contentDiv {
  width: 60%;
  text-align: center;
}

.pageHeader .contentDiv h1 {
  font-family: "Saira Extra Condensed", sans-serif;
  font-size: 80px;
  font-weight: bold;
  margin-bottom: 15px;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  .pageHeader .contentDiv {
    width: 85%;
    text-align: center;
  }

  .pageHeader .contentDiv h1 {
    font-family: "Saira Extra Condensed", sans-serif;
    font-size: 60px;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
  }
}
