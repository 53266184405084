@import url("https://fonts.googleapis.com/css2?family=Saira+Extra+Condensed:wght@300;400;500&display=swap");

.reachSection {
  width: 100%;
  background-color: white;
  padding-top: 80px;
  padding-bottom: 80px;
  color: #0035e9;
}

.reachSection .smallHeader {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 35px;
  /* font-family: "Saira Extra Condensed", sans-serif; */
  font-size: 23px;
  font-weight: normal;
  -webkit-text-stroke: 1px #0035e9;
}

.reachSection .bigHeader {
  margin-bottom: 10px;
  margin-top: 20px;
  text-align: center;
  font-family: "Saira Extra Condensed", sans-serif;
  font-weight: bold;
  font-size: 75px;
  line-height: 75px;
  margin-bottom: 60px;
}

.reachSection .bigHeader span {
  color: transparent;
  -webkit-text-stroke: 1px #0035e9;
  font-weight: normal;
}

.reachPoints {
  /* display: flex;
  margin: 0 auto;
  width: 90%;
  justify-content: space-around; */
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  column-gap: 10px;
  row-gap: 20px;
}

/* .reachPoints .pointDiv img {
  width: 100px;
  margin: 30px;
} */

.reachPoints .pointDiv {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  border: 1px solid #0035e9;
  padding-top: 50px;
  box-shadow: 0px 8px 16px 0px #0036e91f;
  /* background-color: #0036e90b; */
  /* cursor: pointer; */
  transition: transform 0.2s;
  position: relative;
}

/* .reachPoints .pointDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #0035e9;
  padding-top: 30px;
  box-shadow: 0px 8px 16px 0px #0036e91f;
  cursor: pointer;
  transition: transform 0.2s;
  position: relative;
} */

.reachPoints .pointDiv svg {
  position: absolute;
  top: -25px;
  /* left: 50%;
  right: 50%; */
  color: #0035e9;
  background-color: white;
  border-radius: 50%;
  border: 1px solid #0035e9;
  font-size: 50px;
  padding: 9px;
}

.reachPoints .pointDiv img {
  position: absolute;
  top: -25px;
  width: 50px;
  /* left: 50%;
  right: 50%; */
  background-color: white;
  /* border-radius: 50%; */
  /* border: 1px solid #0035e9; */
  font-size: 50px;
  /* padding: 2px; */
  color: #0035e9;
}

.reachPoints .pointDiv:hover {
  transform: scale(1.04);
}

.reachPoints .pointDiv h3 {
  font-size: 1.3rem;
  letter-spacing: 2px;
  color: rgb(48, 48, 48);
  width: 100%;
}

.reachPoints .pointDiv .stats h3 {
  letter-spacing: normal;
  font-size: 45px;
  font-weight: bold;
  color: #0035e9;
  margin: 0;
}

/* .reachPoints .pointDiv .stats {
  display: flex;
  align-items: center;
  letter-spacing: normal;
  font-size: 45px;
  font-weight: bold;
  color: #0035e9;
  margin: 0;
} */

.reachPoints .pointDiv .pointText ul {
  list-style-type: "-";
  list-style: none;
  padding: 0;
  /* margin: 0 auto; */
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: rgb(48, 48, 48);
  line-height: 20px;
}

@media screen and (max-width: 768px) {
  .reachSection {
    width: 100%;
    background-color: white;
    padding-top: 60px;
    padding-bottom: 60px;
    color: #0035e9;
  }

  .reachSection .smallHeader {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 25px;
    /* font-family: "Saira Extra Condensed", sans-serif; */
    font-size: 20px;
    font-weight: normal;
    -webkit-text-stroke: 1px #0035e9;
  }

  .reachSection .bigHeader {
    padding: 0 8px;
    margin-bottom: 10px;
    margin-top: 15px;
    font-size: 48px;
    line-height: 48px;
    margin-bottom: 40px;
  }

  .reachPoints {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    width: 70%;
    margin: 0 auto;
    text-align: center;
    /* column-gap: 10px; */
    /* row-gap: 20px; */
  }

  .reachPoints .pointDiv {
    margin: 8px 0;
  }
}
