.contentSection {
  /* flex: 0.8; */
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.contentSection h3 {
  font-family: "Saira Extra Condensed", sans-serif;
  text-transform: uppercase;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 1.2px;
  /* margin: 0; */
}

.contentSection p {
  margin: 0;
}

.contentSection hr {
  /* margin: 10px; */
  color: #0035e9;
  width: 20%;
}
